



















import { Component, Vue } from 'vue-property-decorator';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import ArrowIcon from 'vue-material-design-icons/KeyboardBackspace.vue';
import TileList from '@/components/shared/TileList.vue';
import { acapellaTiles } from './repertoirContent';
import { Breadcrumb, RouterTile } from '@/typings';

@Component({ components: { Breadcrumbs, ArrowIcon, TileList } })
export default class AcapellaTiles extends Vue {
  acapellaTiles: RouterTile[] = acapellaTiles;
  mobileBreakPoint: MediaQueryList = window.matchMedia('(max-width: 600px)');
  isScreenMobile: boolean = this.mobileBreakPoint.matches;

  mounted(): void {
    this.mobileBreakPoint.onchange = this.mediaQueryHandler;
  }

  get breadcrumbs(): Breadcrumb[] {
    return this.$route.meta.breadcrumb;
  }

  mediaQueryHandler(): void {
    this.isScreenMobile = this.mobileBreakPoint.matches;
  }

  onBackClick(): void {
    this.$router.go(-1);
  }
}
